import React from 'react'
import { Switch } from 'react-router-dom'
import Route from './Route'

import Index from './../pages/Index'
import Confirmar from './../pages/Confirmar'

export default function Routes() {
    return (
        <Switch>
            <Route path="/confirmar/:hash" exact component={Confirmar} />
            <Route path="/:atividade/:plano" exact component={Index} />
            <Route path="/" exact component={Index} />
        </Switch>
    );
}