import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Routes from './routes/Index'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <HashRouter>
      <Routes />
      <ToastContainer />
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
)