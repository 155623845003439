import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import axios from 'axios'
import config from './../config'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Logo from './../img/gestaumdigital.png'
import LoadingGif from './../img/loading.gif'

const useStyles = makeStyles((theme) => ({
    title: {
      textAlign: 'center',
      fontSize: '5em',
      marginTop: '.5em',
      marginBottom: '.5em'
    },
    root: {
        width: '100%',
        maxWidth: '900px',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: '4em',
        padding: theme.spacing(6),
    },
    button: {
        marginTop: theme.spacing(4),
        fontSize: '1.4em'
    },
    text: {
        fontSize: '1.4em'
    },
    logo: {
        maxWidth: '250px'
    },
}))

function Confirmar(props) {
    const classes = useStyles()
    const hash = props.match.params.hash

    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState()
    const [finished, setFinished] = useState(false)

    const submit = useCallback(() => {
        const url = `${config.api}/confirmar_registro`
        const data = {hash}
        axios.post(url, data)
            .then((response) => {
                console.log(response)
                setFinished(true)
                setValid()
            })
            .catch((error) => {
                
            })
    }, [setFinished, hash, setValid])

    useEffect(() => {
        setLoading(true)
        const url = `${config.api}/checar_registro`
        const data = {hash}
        axios.post(url, data)
            .then((response) => {
                const response_data = response.data
                if (response_data.valid && response_data.valid === true) setValid(true)
                else setValid(false)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [setLoading, setValid, hash])

    return (
        <Fragment>
            <Container>
                <Typography variant='h1' className={classes.title}>
                    <img src={Logo} className={classes.logo} alt="Gestaum Contabilidade" />
                </Typography>

                <Paper className={classes.root}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {loading && (
                                <div style={{textAlign: 'center'}}>
                                    <img src={LoadingGif} style={{maxWidth: '200px'}} alt="Loading" />
                                </div>
                            )}
                            {valid === true && (
                                <Fragment>
                                    <p className={classes.text}>Bem vindo!</p>
                                    <p className={classes.text}>Clique abaixo para confirmar a contratatação:</p>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={submit}
                                        className={classes.button}
                                    >
                                        Confirmar a contratação
                                    </Button>
                                </Fragment>
                            )}
                            {valid === false && (
                                <Fragment>
                                    <p className={classes.text}>Link inválido ou o contrato já foi confirmado. Tente novamente!</p>
                                </Fragment>
                            )}
                            {finished && (
                                <Fragment>
                                    <p className={classes.text}>Contratação confirmada!</p>
                                </Fragment>
                            )}
                            
                        </Grid>
                    </Grid>
                    
                </Paper>
            </Container>
        </Fragment>
    )
}

export default Confirmar